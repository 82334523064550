<template>
  <section class="p-6">
    <card-component>
      <div class="has-text-centered mb-6 mt-4">
        <img
          src="https://www.linctechonline.com/wp-content/uploads/2024/12/1b-Linctech-Global-logistics-logo-resized-4-emails.jpg"
          alt="LincTech"
          width="200"
        >
      </div>
      <div class="has-text-centered mb-5">
        <h5
          class="title mt-3"
          style="font-size: 25px; letter-spacing: 1px; font-weight: 700;"
        >
          LincTech Global Logistics - Dashboard Login
        </h5>
      </div>

      <form
        method="POST"
        @submit.prevent="submit"
      >
        <b-field
          label="E-mail Address"
          :type="{ 'is-danger': errors.email }"
          :message="{ [errors.email ? errors.email[0] : '']: errors.email }"
        >
          <b-input
            v-model="form.email"
            name="email"
            type="email"
            required
          />
        </b-field>

        <b-field
          label="Password"
          :type="{ 'is-danger': errors.password }"
          :message="{ [errors.password ? errors.password[0] : '']: errors.password }"
        >
          <b-input
            v-model="form.password"
            type="password"
            name="password"
            required
          />
        </b-field>

        <b-field>
          <b-checkbox
            v-model="form.remember"
            type="is-black"
            class="is-thin"
          >
            Remember me
          </b-checkbox>
        </b-field>

        <div class="mb-4">
          <p @click="forgotPassword" class="is-underlined has-text-link" style="cursor: pointer">Forgot password?</p>
        </div>

        <b-field>
          <div class="control">
            <b-button
              native-type="submit"
              type="is-success"
              :loading="isLoading"
              expanded
            >
              Login
            </b-button>
          </div>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      fIsLoading: false,
      errors: {},
      form: {
        email: '',
        password: '',
        remember: false,
        device_name: 'web-dashboard'
      }
    }
  },
  methods: {
    submit () {
      this.isLoading = true
      this.$auth.login({
        data: this.form,
        redirect: { name: 'home' },
        remember: this.form.remember,
        staySignedIn: true,
        fetchUser: true
      }).catch(resp => {
        this.errors = resp.response.data.errors
        this.isLoading = false
      })
    },
    forgotPassword () {
      this.fIsLoading = true
      this.axios.post('/v1/auth/reset', {
        email: this.form.email
      }).then(() => {
        this.fIsLoading = false
        this.$buefy.snackbar.open({
          message: 'An email has been sent to you with a new temporary password.',
          type: 'is-success'
        })
      }).catch(resp => {
        this.fIsLoading = false
        this.errors = resp.response.data.errors
        this.$buefy.snackbar.open({
          message: 'An error occurred',
          type: 'is-danger'
        })
      })
    }
  }
}
</script>
<style>
.title {
  color: #17561f !important;
}

section.section.is-title-bar ul li:last-child {
  color: #17561f !important;
}

.pagination-link.is-current {
  background-color: #17561f !important;
  border-color: #17561f !important;
  color: #fff;
}
</style>
